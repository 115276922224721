<template>
  <div>
    <!-- 메인 소개 상단 -->
    <div class="content-main-top-container flex relative" id="service">
      <div class="content-main-top flex">
        <div class="text">
          <div class="title" style="line-height: 42px;">개발자가 없어서 <br />창업을 못하고 계시나요?</div>
          <div class="content">런치팩을 이용하면 아이디어만 있어도 <br>  웹/앱 서비스를 창업할 수 있습니다. <br />
            <span style="font-weight:bold">무엇을 기다리시나요? <br> 지금 바로 시장검증/시장선점 하세요!</span></div>
          <div class="btn" style="color: #ffffff; font-weight: 500" @click="clickTheme">테마 구경하기</div>
        </div>
        <div class="image">
          <img src="../../assets/img/img_main@2x.png">
        </div>
      </div>
    </div>

    <div class="image-wave">
      <img src="../../assets/img/img_main_wave@2x.png">
    </div>

    <!-- 메인 소개 하단 -->
    <div class="page-container-1">
      <div class="content-main-bottom-container">
        <div class="content-main-bottom">
          <div class="card-items flex" style="flex-wrap: wrap;">
            <div class="card-item" style="margin: 0px 20px 20px 0px;">
              <div class="numbering">1</div>
              <div class="img"><img src="../../assets/img/img_card_01.png"></div>
              <div class="text">솔루션 이용 신청</div>
            </div>
            <div class="card-item">
              <div class="numbering">2</div>
              <div class="img"><img src="../../assets/img/img_card_02.png"></div>
              <div class="text">결제완료</div>
            </div>
            <div class="card-item" style="margin: 0px 20px 20px 0px;">
              <div class="numbering">3</div>
              <div class="img"><img src="../../assets/img/img_card_03.png"></div>
              <div class="text">관리자 페이지 개설</div>
            </div>
            <div class="card-item">
              <div class="numbering">4</div>
              <div class="img"><img src="../../assets/img/img_card_04.png"></div>
              <div class="text">화면/DB 구성</div>
            </div>
            <div class="card-item">
              <div class="numbering">5</div>
              <div class="img"><img src="../../assets/img/img_card_05.png"></div>
              <div class="text">내 서비스 시작!</div>
            </div>
          </div>
          <div class="content-main-bottom-content">
            <div class="title">런치팩과 함께라면 <br>
              누구나 웹 앱 플랫폼 서비스를 <br>
              창업할 수 있습니다</div>
            <div class="content">런치팩은 창업자들을 위해 개발자 없이도<br> 직접 웹/앱 서비스를 제작할 수 있는 솔루션입니다. <br>
              제작하고자 하는 서비스에 맞춰 솔루션을 세팅하고 <br> 서비스화면과 관리정보를 구성하여 서비스를 시작하세요</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 소개내용 -->
    <!--<div class="page-container-2 flex">
      <div class="content-1-container">
        <div class="content-1">
          <div class="title" style="line-height: 42px;">원하는 테마서비스를 선택하고<br>마음대로 화면을 수정해 보세요 <br class="pc"></div>
          <div class="content">디자인 변경할 때 마다
            디자이너에게 요청하고<br class="mobile"> 개발업체에게 요청하고.. <br> 지치진 않으셨나요?
            런치팩 솔루션은 제공되는 UI 빌더를 통해 <br>
            모든 서비스 화면 페이지를 원하는대로 디자인할 수 있습니다. <br>
            드래그 앤 드롭과 단축키를 이용하여 손쉽게 수정하세요</div>
          <div class="img"><img src="../../assets/img/img_laptop.png">
            <div class="video-container">
              <video src="/static/video/demovideo_builider.mp4"
                    muted autoplay="autoplay" loop="loop"></video>
              &lt;!&ndash;onclick="this.play();"&ndash;&gt;

            </div>
          </div>
        </div>
      </div>
    </div>-->
    <div class="page-container-3">
      <div class="content-2-container">
        <div class="content-2">
          <div class="title" style="line-height: 42px;">비즈니스를 하시려면 <br>
            앱보다  관리자 페이지가 훨씬 더 중요합니다</div>
          <div class="content">런치팩 솔루션은 서비스 제작 뿐만 아니라 <br>
            비즈니스 운영이 가능하도록 맞춤형 관리자 페이지를 제공합니다. <br>
            서비스 운영을 위해 별도의 개발과정은 필요 없습니다. <br>
            솔루션 이용 즉시 운영에 필요한<br class="mobile"> 정산, 회원, 상품 등을 바로 관리할 수 있습니다. <br>
          </div>
          <div class="img"><img src="../../assets/img/img_laptop.png">
            <div class="content-slide-customdb" id="content-slide-customdb">
              <slick ref="slick3"
                     @afterChange="onChange"
                     :options="options2">
                <div v-for="(item, idx) in customDBSlideItems" :key="'slide-'+idx" class="item slick3-item">
                  <div style="overflow: hidden;">
                    <img :src="item.img" class="slick3-img">
                  </div>
                </div>
              </slick>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-container-4">
      <div class="content-3-container">
        <div class="content-3">
          <div>
            <div class="title service-mobile-title" style="line-height: 42px;">런치팩은 창업자의 초기 시장 선점을 위해
              <br>전체 클라이언트 단을 커버할 수 있도록 제공합니다. </div>
            <div class="content">
              <div v-for="(chk,idx) in checks" :key="'chk'+idx"><div><i class="material-icons">check</i></div><div class="text">{{chk}}</div></div>
            </div>
          </div>
          <div class="img"><img src="../../assets/img/img_info.png"></div>
        </div>
      </div>
    </div>

    <sweet-modal ref="modal">
      <lp-service-inquiry-modal @closeModal="$refs.modal.close()"></lp-service-inquiry-modal>
    </sweet-modal>
  </div>
</template>

<script>
  import Slick from 'vue-slick'
  import { SweetModal } from 'sweet-modal-vue';
  import LpServiceInquiryModal from "../modal/LpServiceInquiryModal";

  export default {
    name: "service",
    components: {
      LpServiceInquiryModal,
      Slick,
      SweetModal,
    },
    mounted() {
      if(window.innerWidth < 700) {
        this.slideAmount = 1;
      }

      else {
        this.slideAmount = 3;
      }
    },
    data() {
      return {
        vDemo: false,
        slick: undefined,
        currentSlide: 0,
        slideAmount: 3,
        screenWidth: screen.width,
        // options: {
        //   infinite: true,
        //   variableWidth: false,
        //   centerPadding: '0',
        //   centerMode: false,
        //   autoplay: true,
        //   autoplaySpeed: 2500,
        //   arrows: false,
        //   dots: false,
        //   slidesToShow: 3,
        //   slidesToScroll: 1
        // },
        options2: {
          infinite: true,
          variableWidth: false,
          centerPadding: '0',
          centerMode: false,
          autoplay: true,
          arrows: false,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1
        },
        checks: [
          'PC웹 (모바일 웹 뷰로 제공)',
          '모바일 웹',
          // '안드로이드 앱',
          // 'iOS 앱',
          '관리자 페이지',
          '파트너(업체) 관리 페이지'
        ],
        slideItems: [
          {
            img: '/static/img/img_slide_05.png',
            title: '견적서 발송을 위해\n파트너 페이지 제공',
            content: '입점 제휴 된 파트너 업체에서 바로\n 견적요청을 확인하고 고객에게 견적서를\n발송할 수 있게 파트너 관리자 페이지를 제공합니다'
          },
          {
            img: '/static/img/img_slide_06.png',
            title: '관리자 맞춤형\n견적요청 양식 폼',
            content: '고객이 견적 요청 시 작성하는 폼 양식을\n다양한 형태로 제공하여 원하는대로\n수정하고 조합하여 폼을 만들 수 있습니다'
          },
          {
            img: '/static/img/img_slide_03.png',
            title: '입점 파트너에게\n견적 발송',
            content: '사용자가 요청한 견적을 인테리어 \n파트너업체에게 직접 선정해서 보내거나\n자동으로 매칭해서 보낼 수 있습니다'
          },
          {
            img: '/static/img/img_slide_02.png',
            title: '테마 커스터마이징\nUI 빌더 지원',
            content: 'UI 빌더를 통해 화면디자인을\n창업자가 원하는대로 수정하고\n커스터마이징 할 수 있습니다'
          },
          {
            img: '/static/img/img_slide_04.png',
            title: '서비스 운영 관리\n솔루션 제공',
            content: '일반적으로 플랫폼 서비스에 필요한\n회원관리, 파트너관리, 상품관리, 공지사항관리\nFAQ 관리 등을 기본적으로 제공합니다'
          },
          {
            img: '/static/img/img_slide_01.png',
            title: '최신 UIUX를 반영한\n간결하고 직관적인 디자인',
            content: '사용자들이 어려워하지 않고\n쉽게 서비스를 이용할 수 있습니다'
          }
        ],
        customerSlideItems: [
          {
            img: '/static/img/img_customer_london.png',
            title: '런던티켓',
            content: '런던 뮤지컬 및 엔터테인먼트 티켓을\n모아서 보여주는 서비스',
            link: 'http://actuallyticket.com'
          },
          {
            img: '/static/img/img_customer_kidsday.png',
            title: '키즈데이',
            content: '영유아 체험학습 및 행사정보를\n모아서 보여주는 서비스',
            link: 'http://kidsday.kr'
          },
          {
            img: '/static/img/img_customer_farmerspace.png',
            title: '파머스페이스',
            content: '생산 원료가 필요한 가공업체와\n잉여 농산물을 보유한 농가를 이어주는 서비스',
            link: 'http://13.115.179.147/home'
          }
        ],
        customDBSlideItems: [
          {
            img: '/static/img/img_backoffice_01.png'
          },
          {
            img: '/static/img/img_backoffice_02.png'
          }
        ]
      }
    },

    methods: {
      clickTheme() {
        this.setGa('테마 구경하기', '클릭', 'store', 1);
        this.$router.push('store');
      },
      onChange(event, slick, currentSlide) {
        this.currentSlide = currentSlide;
      },
      onModal (type) {
        if (type === 'type_month') this.params.product = '580000';
        if (type === 'type_opend') this.params.product = '10000000';
        this.$refs.modal.open();
      },
      toast(msg) {
        this.$root.$emit('toast', msg);
      }
    }
  }
</script>

<style scoped>
  .slick3-img {
    position: relative;
    left: 0px;
  }

  .title {
    font-weight: 400;
  }

  .slick-slide img {
    padding: 0;
  }
</style>
